import { default as _5_45month_45cart_45offervY0wRdxnoJMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/5-month-cart-offer.vue?macro=true";
import { default as _6_45month_45half_45pricepbDSVgVcBpMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/6-month-half-price.vue?macro=true";
import { default as aboutjh4vLiXNyxMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/about.vue?macro=true";
import { default as billingJiilAj6OUeMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/account/billing.vue?macro=true";
import { default as indexD5arazsyFoMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/account/index.vue?macro=true";
import { default as profileYREcDzuFwwMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/account/profile.vue?macro=true";
import { default as accounthVolhFin2IMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/account.vue?macro=true";
import { default as authorizeWXBuwxgtwwMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/authorize.vue?macro=true";
import { default as bbc_45promoDN7KaumyLdMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/bbc-promo.vue?macro=true";
import { default as chatZgt5KyXFA0Meta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/chat.vue?macro=true";
import { default as compass_45embed2eHSzqdhT9Meta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/compass-embed.vue?macro=true";
import { default as compassCya2F7kOfsMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/compass.vue?macro=true";
import { default as contactJhaoZIMK4jMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/contact.vue?macro=true";
import { default as hyperfibrej3PWKdwL3LMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/hyperfibre.vue?macro=true";
import { default as indexPectNlQq6HMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/index.vue?macro=true";
import { default as internet_45speed_45test6wUZVYGzoQMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/internet-speed-test.vue?macro=true";
import { default as loginwxnEip3xw0Meta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/login.vue?macro=true";
import { default as logoutIHe9deWiY6Meta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/logout.vue?macro=true";
import { default as nzgeo_45exclusive_45offer5IIVaFyepEMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/nzgeo-exclusive-offer.vue?macro=true";
import { default as our_45plans7voVnaL5grMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/our-plans.vue?macro=true";
import { default as plans5TVFwv3gKaMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/plans.vue?macro=true";
import { default as privacyPHydSgWNZeMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/privacy.vue?macro=true";
import { default as promotionsgz8WS8IWaMMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/promotions.vue?macro=true";
import { default as _1k1Ig2Rp91qMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/register/1.vue?macro=true";
import { default as _2BJPxLhoFUrMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/register/2.vue?macro=true";
import { default as _3Nobgaq335vMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/register/3.vue?macro=true";
import { default as _4A3nO8OMfB6Meta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/register/4.vue?macro=true";
import { default as _5Ye6qBWUmFzMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/register/5.vue?macro=true";
import { default as _6A17FwKr7QLMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/register/6.vue?macro=true";
import { default as indexlwLkXzyXCVMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/register/index.vue?macro=true";
import { default as successmrgJO4SgtlMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/register/success.vue?macro=true";
import { default as registerQtJzuEwonoMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/register.vue?macro=true";
import { default as simply_45broadbandaNEyiO4GexMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/simply-broadband.vue?macro=true";
import { default as studentcard_45exclusive_45offerNnOpyE8GcKMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/studentcard-exclusive-offer.vue?macro=true";
import { default as sustainabilityq6TSIlZkivMeta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/sustainability.vue?macro=true";
import { default as terms2PZcUa10T9Meta } from "/codebuild/output/src3994426323/src/zeronet-web/pages/terms.vue?macro=true";
export default [
  {
    name: _5_45month_45cart_45offervY0wRdxnoJMeta?.name ?? "5-month-cart-offer",
    path: _5_45month_45cart_45offervY0wRdxnoJMeta?.path ?? "/5-month-cart-offer",
    meta: _5_45month_45cart_45offervY0wRdxnoJMeta || {},
    alias: _5_45month_45cart_45offervY0wRdxnoJMeta?.alias || [],
    redirect: _5_45month_45cart_45offervY0wRdxnoJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/5-month-cart-offer.vue").then(m => m.default || m)
  },
  {
    name: _6_45month_45half_45pricepbDSVgVcBpMeta?.name ?? "6-month-half-price",
    path: _6_45month_45half_45pricepbDSVgVcBpMeta?.path ?? "/6-month-half-price",
    meta: _6_45month_45half_45pricepbDSVgVcBpMeta || {},
    alias: _6_45month_45half_45pricepbDSVgVcBpMeta?.alias || [],
    redirect: _6_45month_45half_45pricepbDSVgVcBpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/6-month-half-price.vue").then(m => m.default || m)
  },
  {
    name: aboutjh4vLiXNyxMeta?.name ?? "about",
    path: aboutjh4vLiXNyxMeta?.path ?? "/about",
    meta: aboutjh4vLiXNyxMeta || {},
    alias: aboutjh4vLiXNyxMeta?.alias || [],
    redirect: aboutjh4vLiXNyxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/about.vue").then(m => m.default || m)
  },
  {
    path: accounthVolhFin2IMeta?.path ?? "/account",
    children: [
  {
    name: billingJiilAj6OUeMeta?.name ?? "account-billing",
    path: billingJiilAj6OUeMeta?.path ?? "billing",
    meta: billingJiilAj6OUeMeta || {},
    alias: billingJiilAj6OUeMeta?.alias || [],
    redirect: billingJiilAj6OUeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: indexD5arazsyFoMeta?.name ?? "account",
    path: indexD5arazsyFoMeta?.path ?? "",
    meta: indexD5arazsyFoMeta || {},
    alias: indexD5arazsyFoMeta?.alias || [],
    redirect: indexD5arazsyFoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: profileYREcDzuFwwMeta?.name ?? "account-profile",
    path: profileYREcDzuFwwMeta?.path ?? "profile",
    meta: profileYREcDzuFwwMeta || {},
    alias: profileYREcDzuFwwMeta?.alias || [],
    redirect: profileYREcDzuFwwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/account/profile.vue").then(m => m.default || m)
  }
],
    name: accounthVolhFin2IMeta?.name ?? undefined,
    meta: accounthVolhFin2IMeta || {},
    alias: accounthVolhFin2IMeta?.alias || [],
    redirect: accounthVolhFin2IMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: authorizeWXBuwxgtwwMeta?.name ?? "authorize",
    path: authorizeWXBuwxgtwwMeta?.path ?? "/authorize",
    meta: authorizeWXBuwxgtwwMeta || {},
    alias: authorizeWXBuwxgtwwMeta?.alias || [],
    redirect: authorizeWXBuwxgtwwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/authorize.vue").then(m => m.default || m)
  },
  {
    name: bbc_45promoDN7KaumyLdMeta?.name ?? "bbc-promo",
    path: bbc_45promoDN7KaumyLdMeta?.path ?? "/bbc-promo",
    meta: bbc_45promoDN7KaumyLdMeta || {},
    alias: bbc_45promoDN7KaumyLdMeta?.alias || [],
    redirect: bbc_45promoDN7KaumyLdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/bbc-promo.vue").then(m => m.default || m)
  },
  {
    name: chatZgt5KyXFA0Meta?.name ?? "chat",
    path: chatZgt5KyXFA0Meta?.path ?? "/chat",
    meta: chatZgt5KyXFA0Meta || {},
    alias: chatZgt5KyXFA0Meta?.alias || [],
    redirect: chatZgt5KyXFA0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: compass_45embed2eHSzqdhT9Meta?.name ?? "compass-embed",
    path: compass_45embed2eHSzqdhT9Meta?.path ?? "/compass-embed",
    meta: compass_45embed2eHSzqdhT9Meta || {},
    alias: compass_45embed2eHSzqdhT9Meta?.alias || [],
    redirect: compass_45embed2eHSzqdhT9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/compass-embed.vue").then(m => m.default || m)
  },
  {
    name: compassCya2F7kOfsMeta?.name ?? "compass",
    path: compassCya2F7kOfsMeta?.path ?? "/compass",
    meta: compassCya2F7kOfsMeta || {},
    alias: compassCya2F7kOfsMeta?.alias || [],
    redirect: compassCya2F7kOfsMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/compass.vue").then(m => m.default || m)
  },
  {
    name: contactJhaoZIMK4jMeta?.name ?? "contact",
    path: contactJhaoZIMK4jMeta?.path ?? "/contact",
    meta: contactJhaoZIMK4jMeta || {},
    alias: contactJhaoZIMK4jMeta?.alias || [],
    redirect: contactJhaoZIMK4jMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: hyperfibrej3PWKdwL3LMeta?.name ?? "hyperfibre",
    path: hyperfibrej3PWKdwL3LMeta?.path ?? "/hyperfibre",
    meta: hyperfibrej3PWKdwL3LMeta || {},
    alias: hyperfibrej3PWKdwL3LMeta?.alias || [],
    redirect: hyperfibrej3PWKdwL3LMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/hyperfibre.vue").then(m => m.default || m)
  },
  {
    name: indexPectNlQq6HMeta?.name ?? "index",
    path: indexPectNlQq6HMeta?.path ?? "/",
    meta: indexPectNlQq6HMeta || {},
    alias: indexPectNlQq6HMeta?.alias || [],
    redirect: indexPectNlQq6HMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: internet_45speed_45test6wUZVYGzoQMeta?.name ?? "internet-speed-test",
    path: internet_45speed_45test6wUZVYGzoQMeta?.path ?? "/internet-speed-test",
    meta: internet_45speed_45test6wUZVYGzoQMeta || {},
    alias: internet_45speed_45test6wUZVYGzoQMeta?.alias || [],
    redirect: internet_45speed_45test6wUZVYGzoQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/internet-speed-test.vue").then(m => m.default || m)
  },
  {
    name: loginwxnEip3xw0Meta?.name ?? "login",
    path: loginwxnEip3xw0Meta?.path ?? "/login",
    meta: loginwxnEip3xw0Meta || {},
    alias: loginwxnEip3xw0Meta?.alias || [],
    redirect: loginwxnEip3xw0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutIHe9deWiY6Meta?.name ?? "logout",
    path: logoutIHe9deWiY6Meta?.path ?? "/logout",
    meta: logoutIHe9deWiY6Meta || {},
    alias: logoutIHe9deWiY6Meta?.alias || [],
    redirect: logoutIHe9deWiY6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: nzgeo_45exclusive_45offer5IIVaFyepEMeta?.name ?? "nzgeo-exclusive-offer",
    path: nzgeo_45exclusive_45offer5IIVaFyepEMeta?.path ?? "/nzgeo-exclusive-offer",
    meta: nzgeo_45exclusive_45offer5IIVaFyepEMeta || {},
    alias: nzgeo_45exclusive_45offer5IIVaFyepEMeta?.alias || [],
    redirect: nzgeo_45exclusive_45offer5IIVaFyepEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/nzgeo-exclusive-offer.vue").then(m => m.default || m)
  },
  {
    name: our_45plans7voVnaL5grMeta?.name ?? "our-plans",
    path: our_45plans7voVnaL5grMeta?.path ?? "/our-plans",
    meta: our_45plans7voVnaL5grMeta || {},
    alias: our_45plans7voVnaL5grMeta?.alias || [],
    redirect: our_45plans7voVnaL5grMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/our-plans.vue").then(m => m.default || m)
  },
  {
    name: plans5TVFwv3gKaMeta?.name ?? "plans",
    path: plans5TVFwv3gKaMeta?.path ?? "/plans",
    meta: plans5TVFwv3gKaMeta || {},
    alias: plans5TVFwv3gKaMeta?.alias || [],
    redirect: plans5TVFwv3gKaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: privacyPHydSgWNZeMeta?.name ?? "privacy",
    path: privacyPHydSgWNZeMeta?.path ?? "/privacy",
    meta: privacyPHydSgWNZeMeta || {},
    alias: privacyPHydSgWNZeMeta?.alias || [],
    redirect: privacyPHydSgWNZeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: promotionsgz8WS8IWaMMeta?.name ?? "promotions",
    path: promotionsgz8WS8IWaMMeta?.path ?? "/promotions",
    meta: promotionsgz8WS8IWaMMeta || {},
    alias: promotionsgz8WS8IWaMMeta?.alias || [],
    redirect: promotionsgz8WS8IWaMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/promotions.vue").then(m => m.default || m)
  },
  {
    path: registerQtJzuEwonoMeta?.path ?? "/register",
    children: [
  {
    name: _1k1Ig2Rp91qMeta?.name ?? "register-1",
    path: _1k1Ig2Rp91qMeta?.path ?? "1",
    meta: _1k1Ig2Rp91qMeta || {},
    alias: _1k1Ig2Rp91qMeta?.alias || [],
    redirect: _1k1Ig2Rp91qMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/register/1.vue").then(m => m.default || m)
  },
  {
    name: _2BJPxLhoFUrMeta?.name ?? "register-2",
    path: _2BJPxLhoFUrMeta?.path ?? "2",
    meta: _2BJPxLhoFUrMeta || {},
    alias: _2BJPxLhoFUrMeta?.alias || [],
    redirect: _2BJPxLhoFUrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/register/2.vue").then(m => m.default || m)
  },
  {
    name: _3Nobgaq335vMeta?.name ?? "register-3",
    path: _3Nobgaq335vMeta?.path ?? "3",
    meta: _3Nobgaq335vMeta || {},
    alias: _3Nobgaq335vMeta?.alias || [],
    redirect: _3Nobgaq335vMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/register/3.vue").then(m => m.default || m)
  },
  {
    name: _4A3nO8OMfB6Meta?.name ?? "register-4",
    path: _4A3nO8OMfB6Meta?.path ?? "4",
    meta: _4A3nO8OMfB6Meta || {},
    alias: _4A3nO8OMfB6Meta?.alias || [],
    redirect: _4A3nO8OMfB6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/register/4.vue").then(m => m.default || m)
  },
  {
    name: _5Ye6qBWUmFzMeta?.name ?? "register-5",
    path: _5Ye6qBWUmFzMeta?.path ?? "5",
    meta: _5Ye6qBWUmFzMeta || {},
    alias: _5Ye6qBWUmFzMeta?.alias || [],
    redirect: _5Ye6qBWUmFzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/register/5.vue").then(m => m.default || m)
  },
  {
    name: _6A17FwKr7QLMeta?.name ?? "register-6",
    path: _6A17FwKr7QLMeta?.path ?? "6",
    meta: _6A17FwKr7QLMeta || {},
    alias: _6A17FwKr7QLMeta?.alias || [],
    redirect: _6A17FwKr7QLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/register/6.vue").then(m => m.default || m)
  },
  {
    name: indexlwLkXzyXCVMeta?.name ?? "register",
    path: indexlwLkXzyXCVMeta?.path ?? "",
    meta: indexlwLkXzyXCVMeta || {},
    alias: indexlwLkXzyXCVMeta?.alias || [],
    redirect: indexlwLkXzyXCVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: successmrgJO4SgtlMeta?.name ?? "register-success",
    path: successmrgJO4SgtlMeta?.path ?? "success",
    meta: successmrgJO4SgtlMeta || {},
    alias: successmrgJO4SgtlMeta?.alias || [],
    redirect: successmrgJO4SgtlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/register/success.vue").then(m => m.default || m)
  }
],
    name: registerQtJzuEwonoMeta?.name ?? undefined,
    meta: registerQtJzuEwonoMeta || {},
    alias: registerQtJzuEwonoMeta?.alias || [],
    redirect: registerQtJzuEwonoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/register.vue").then(m => m.default || m)
  },
  {
    name: simply_45broadbandaNEyiO4GexMeta?.name ?? "simply-broadband",
    path: simply_45broadbandaNEyiO4GexMeta?.path ?? "/simply-broadband",
    meta: simply_45broadbandaNEyiO4GexMeta || {},
    alias: simply_45broadbandaNEyiO4GexMeta?.alias || [],
    redirect: simply_45broadbandaNEyiO4GexMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/simply-broadband.vue").then(m => m.default || m)
  },
  {
    name: studentcard_45exclusive_45offerNnOpyE8GcKMeta?.name ?? "studentcard-exclusive-offer",
    path: studentcard_45exclusive_45offerNnOpyE8GcKMeta?.path ?? "/studentcard-exclusive-offer",
    meta: studentcard_45exclusive_45offerNnOpyE8GcKMeta || {},
    alias: studentcard_45exclusive_45offerNnOpyE8GcKMeta?.alias || [],
    redirect: studentcard_45exclusive_45offerNnOpyE8GcKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/studentcard-exclusive-offer.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityq6TSIlZkivMeta?.name ?? "sustainability",
    path: sustainabilityq6TSIlZkivMeta?.path ?? "/sustainability",
    meta: sustainabilityq6TSIlZkivMeta || {},
    alias: sustainabilityq6TSIlZkivMeta?.alias || [],
    redirect: sustainabilityq6TSIlZkivMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: terms2PZcUa10T9Meta?.name ?? "terms",
    path: terms2PZcUa10T9Meta?.path ?? "/terms",
    meta: terms2PZcUa10T9Meta || {},
    alias: terms2PZcUa10T9Meta?.alias || [],
    redirect: terms2PZcUa10T9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3994426323/src/zeronet-web/pages/terms.vue").then(m => m.default || m)
  }
]